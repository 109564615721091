<template>
    <div class="frame mobile" ref="frame" :class="{mobile:isMobile}">
        <div class="frame-title">
            <span>欢迎注册</span>
            <span style="float: right;">中国家居产业智联网</span>
        </div>
        <div class="step">
            <ItemStep></ItemStep>
        </div>
        <div class="reg-frm" :class="{'active':activeName=='create' && $store.state.regStep==1}">
            <div>
                <div v-if="$store.state.regStep==1" class="flex flex-justify-around login-tab">
                    <div v-bind:class="{ active: activeName=='create' }" @click="activeName='create'">创建新企业</div>
                    <div v-bind:class="{ active: activeName=='join' }" @click="activeName='join'">加入已有企业</div>
                </div>
                <div style="margin-top: 20px;">
                    <Create v-if="activeName=='create'" @on-step="onStep"></Create>
                    <Join v-if="activeName=='join'"></Join>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
    import Create from './Create.vue';
    import Join from './Join.vue';
    import ItemStep from './ItemStep.vue';
    export default {
        components: {
            Create, Join,ItemStep
        },
        data() {
            return {
                activeName: 'create',
                // 组件内注册的步骤,用于判断组件是否在第一步,控制tab部分是否显示
                step: 0,
                isMobile:false
            }
        },
        created() {
            this.$store.state.regStep=1;
        },
        mounted(){
            this.isMobile = this.$refs['frame'].offsetWidth<600;
            window.onresize = () => {
                this.isMobile = this.$refs['frame'].offsetWidth<600;
            }
        },
        methods: {
            onStep(_step) {
                this.step = _step;
            }
        },
        destroyed(){
            window.onresize = null;
        }
    }
</script>
<style scoped src="../style.css"></style>
<style scoped>
    .frame{
        color:rgba(41,41,41,1);
        background:rgba(255,255,255,1);
        height:100%;
        overflow: auto;
    }
    .frame-title{
        font-size:24px;
        font-weight:bold;
        width: 330px;
        margin: 80px auto 50px;
        overflow: hidden;
    }
    
    .reg-frm {
        margin: 12% auto 0;
        width: 600px;
        background: rgba(255, 254, 255, 1);
        box-shadow: 0px 0px 20px 0px rgba(198, 198, 198, 0.5);
        border-radius: 20px;
        overflow: hidden;
    }
    .reg-frm.active {
        width: 100%;
    }

    .reg-frm>div {
        width: 275px;
        margin: 30px auto;
    }
    .reg-frm.active>div {
        width: 100%;
    }

    .login-tab>div {
        cursor: pointer;
        font-size: 16px;
        padding: 8px 0;
    }

    .login-tab>div.active {
        color: #5074EE;
        border-bottom: 2px solid #5074EE;
    }
    .frame.mobile .frame-title{
      display: none;
    }
    .frame.mobile .step{
        display: none;
    }
    .frame.mobile .reg-frm {
        width: auto;
        box-shadow:none;
    }
</style>