<template>
    <div class="quote" style="text-align: center;">
        <div class="steps flex">
            <div :class="{'active':regStep==1}" class="flex flex-align-center">
                <div class="num">1</div>
                <div>创建新企业</div>
                <div><i class="iconfont icon-fanhuitubiao1"></i></div>
            </div>
            <div :class="{'active':regStep==2}" class="flex flex-align-center">
                <div class="num">2</div>
                <div>选择企业类型</div>
                <div><i class="iconfont icon-fanhuitubiao1"></i></div>
            </div>
            <div :class="{'active':regStep==3}" class="flex flex-align-center">
                <div class="num">3</div>
                <div>注册账号</div>
                <div><i class="iconfont icon-fanhuitubiao1"></i></div>
            </div>
            <div :class="{'active':regStep==4}" class="flex flex-align-center">
                <div class="num">4</div>
                <div>注册成功</div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            // regStep: Number
        },
        data() {
            return {
                prices:null,
            }
        },
        watch: {},
        computed:{
            regStep(){
                return this.$store.state.regStep;
            }
        },
        created () {

        },
        methods: {
            
        }
    }
</script>
<style scoped>
    .steps {
        width: 630px;
        margin: 0 auto 30px;
        padding: 10px 0;
        font-size: 16px;
        color: #292929;
    }
    .steps>div {
        margin-right: 30px;
    }
    .steps>div:last-child {
        margin-right: 0;
    }
    .steps .num {
        width: 30px;
        height: 30px;
        line-height: 30px;
        background: #4D4D4D;
        border-radius: 50%;
        text-align: center;
        font-size: 16px;
        color: #fff;
        margin-right: 10px;
    }
    .steps>div.active .num {
        background: #5074EE;
    }
    .steps i {
        font-size: 20px;
        color: #292929;
        margin-left: 4px;
    }

</style>