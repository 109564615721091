<!-- 手机号注册 -->
<template>
    <div class="create2">
        <el-form :model="ruleForm" :rules="rules" ref="formCheck3">
            <el-form-item style="text-align: center;">
                <span class="create2-title">创建用户账号</span>
            </el-form-item>
            <el-form-item style="margin-bottom:20px;" prop="user_mobile">
                <el-input v-model="ruleForm.user_mobile" :maxlength="11" placeholder="请输入管理员手机号"></el-input>
            </el-form-item>
            <el-form-item style="margin-bottom:20px;" prop="captcha">
                <el-input v-model="ruleForm.captcha" placeholder="请输入图形验证码" class="reg-create-captcha-ipt">
                    <div slot="append" v-bind:style="{background:captchaBg}" class="captcha-img" @click="refreshCaptcha">
                    </div>
                </el-input>
            </el-form-item>
            <el-form-item style="margin-bottom:20px;" prop="mobile_code">
                <el-input placeholder="请输入短信验证码" v-model="ruleForm.mobile_code">
                    <template slot="append">
                        <div v-if="invsec<=0" @click="getMobileCode" style="cursor: pointer;">获取验证码</div>
                        <div v-if="invsec>0">{{invsec}}秒后重试</div>
                    </template>
                </el-input>
            </el-form-item>
            <el-form-item style="margin-bottom:20px;" prop="user_name">
                <el-input v-model="ruleForm.user_name" :maxlength="11" placeholder="管理员姓名(企业内沟通使用,建议实名)"></el-input>
            </el-form-item>
            <el-form-item style="margin-bottom:20px;" prop="user_pass">
                <div>
                    <el-input placeholder="请输入密码(8～20位,区分大小写)" v-model="ruleForm.user_pass" :maxlength="20" show-password></el-input>
                </div>
                <div v-if="ruleForm.user_pass && ruleForm.user_pass.length>7" class="plan" style="margin-top:14px;">
                    <div class='plan-progress flex'>
                        <div :style="planNum && planNum>=1 ? 'background:red;': ''" class="flex-1"></div>
                        <div :style="planNum && planNum>=2 ? 'background:orange;': ''" class="flex-1"></div>
                        <div :style="planNum && planNum==4 ? 'background:#00D1B2;': ''" class="flex-1"></div>
                    </div>
                    <div class="plan-text flex flex-justify-around">
                        <span style="color:red;">弱</span>
                        <span style="color:orange;">中</span>
                        <span style="color:#00D1B2;">强</span>
                    </div>
                </div>
            </el-form-item>
            <el-form-item style="margin-bottom:20px;" prop="cpassword">
                <el-input placeholder="请再次输入密码" v-model="ruleForm.cpassword" show-password></el-input>
            </el-form-item>
        </el-form>

    </div>
</template>
<script>
    import { getCaptCha, sendSmsCode } from '@/service/common';
    import { createMobileAccount,createMobileAccountAgo } from '@/service/account';
    import { phoneReg } from "@/utils";
    export default {
        model: {
            prop: "form",
            event: "change"
        },
        props:['form'],
        data() {
            let checkPhone = (rule, value, callback) => {
                if (!value) {
                return callback(new Error('请输入管理员手机号'));
                } else {
                    const reg = phoneReg;
                    if (reg.test(value)) {
                        createMobileAccountAgo({
                            mobile: this.ruleForm.user_mobile,
                        }).then((rst) => {
                            if (rst.reg==1) {
                                return callback(new Error('该手机号已注册，请点击右上角登录'));
                            } else {
                                callback();
                            }
                        }).catch(err => {
                            console.log(err);
                            callback();
                        });
                    } else {
                        return callback(new Error('请输入正确的手机号'));
                    }
                }
            };
            let checkPass = (rule, value, callback) => {
                if (!value) {
                    return callback(new Error('请输入密码'));
                } else {
                    let reg = /[\u4E00-\u9FA5]/g
                    if (reg.test(value)) {
                        callback(new Error('密码只限数字、字母、特殊字符'));
                    } else if (value.length<8 || value.length>20) {
                        callback(new Error('请输入8-20位'));
                    } else{
                        callback();
                    }
                }
            };
            let checkCpass = (rule, value, callback) => {
                if (!value) {
                    return callback(new Error('请再次输入密码'));
                } else {
                    if (value == this.ruleForm.user_pass) {
                        callback();
                    } else {
                        return callback(new Error('与上面密码不匹配'));
                    }
                }
            };
            return {
                // popTitle: null,
                // popVisible: false,
                ruleForm: {
                    user_name: '',
                    user_mobile: '',
                    user_pass: '',
                    mobile_code: '',
                    captcha: '',
                    captcha_token: '',
                },
                rules: {
                    user_name: [
                        { required: true, message: '请输入管理员姓名', trigger: 'blur' }
                    ],
                    user_mobile: [{validator: checkPhone, trigger: 'blur'}],
                    captcha: [
                        { required: true, message: '请输入图形验证码', trigger: 'blur' }
                    ],
                    mobile_code: [
                        { required: true, message: '请输入短信验证码', trigger: 'blur' }
                    ],
                    user_pass: [
                        { validator: checkPass, trigger: 'blur' }
                    ],
                    cpassword: [
                        { validator: checkCpass, trigger: 'blur' }
                    ],
                },
                captchaBg: null,
                captchaToken: null,
                invsec: 0,
                timer: null,
                planNum:0,
            }
        },
        watch:{
            form:{
                handler() {
                    this.ruleForm=this.form;
                },
                deep: true
            },
            ruleForm:{
                handler() {
                    this.onChange();
                },
                deep: true
            },
            'ruleForm.user_pass':{
                handler(newValue, oldValue) {
                    // console.log(newValue.length);
                    if (newValue) {
                        this.checkPlan(newValue);
                    }
                },
                deep: true
            }
        },
        mounted() {
            this.ruleForm=this.form;
            this.refreshCaptcha();
        },
        methods: {
            checkPlan(value) {
                // console.log(value);
                //正则表达式验证符合要求的
                if(value.length < 8 || value.length > 20) return this.planNum;
                if((/\d/.test(value)) || (/[a-z]/.test(value)) || (/[A-Z]/.test(value)) || (/^.*[^a-zA-Z0-9]+.*/.test(value))) this.planNum=1; //数字
                if((/\d/.test(value)) && (/[a-z]/.test(value)) || (/\d/.test(value)) && (/[A-Z]/.test(value)) || (/\d/.test(value)) && (/^.*[^a-zA-Z0-9]+.*/.test(value))) this.planNum=2; //小写
                if(this.planNum==2 && (/[A-Z]/.test(value)) || this.planNum==2 && (/^.*[^a-zA-Z0-9]+.*/.test(value))) this.planNum=3; //大写  
                if(this.planNum==3 && (/^.*[^a-zA-Z0-9]+.*/.test(value)) || this.planNum==3 && (/[A-Z]/.test(value))) this.planNum=4; //特殊字符
                console.log(this.planNum);
                //逻辑处理
                switch(this.planNum) {
                    case 1:
                        return 1;
                    case 2:
                        return 2;
                    case 3:
                        return 3;
                    case 4:
                        return value.length < 10 ? 3 : 4;
                }
                console.log(this.planNum);
            },
            onChange() {
                this.$emit('change', this.ruleForm);
            },
            refreshCaptcha() {
                getCaptCha().then(rsp => {
                    this.captchaBg = `url('data:image/svg+xml,${encodeURIComponent(rsp.svg)}') no-repeat center`;
                    this.captchaToken = rsp.token;
                }).catch(err => {
                    this.$message.error(err.message);
                })
            },
            getMobileCode() {
                sendSmsCode(this.ruleForm.user_mobile).then(() => {
                    this.invsec = 60;
                    this.timer = setInterval(() => {
                        if (this.invsec == 0) {
                            clearInterval(this.timer);
                            return;
                        }
                        this.invsec--;
                    }, 1000);
                }).catch(err => {
                    this.$message.error(err.message);
                })
            },
            // 验证手机号是否存在
            verify_phone() {
                this.$refs['formCheck3'].validate((valid) => {
                    if (valid) {
                        this.submitForm();
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
            submitForm() {
                console.log(this.ruleForm);
                this.ruleForm.captcha_token = this.captchaToken;
                // 注册账号
                createMobileAccount(this.ruleForm).then(() => {
                    this.$store.state.regStep=4;
                    // this.$router.push('/create_comp');
                    this.$router.push('/mobile/invite');
                }).catch(err => {
                    // this.refreshCaptcha();// 刷新二维码
                    console.log(err);
                    this.$message.error(err.message);
                });
            },
            
        }
    }

</script>
<style>
    .reg-create-captcha-ipt .el-input-group__append {
        padding: 0 !important;
        overflow: hidden;
    }
</style>
<style scoped>
    .create2-title {
        font-size: 16px;
        font-weight: bold;
        color: rgba(77, 77, 77, 1);
    }
    .captcha-img {
        height: 38px;
        background-size: 100% 100% !important;
        width: 110px;
        cursor: pointer;
    }

    /* 密码强度 */
    .plan-progress>div {
		height: 10px;
        background: #eee;
        margin-bottom: 8px;
	}
	.plan-text span{
        font-size: 14px;
		line-height: 16px;
	}
</style>